<template lang="pug">
v-content.grey.darken-3
  v-overlay(:value="updating")
    v-progress-circular(indeterminate size="64")
  v-app-bar(app dense color="primary")
    v-btn(icon :to="{ name: 'Home' }" exact)
      v-icon mdi-home
    v-spacer
    v-btn(@click="updateJson")
      v-icon.mr-2 mdi-download
      | Обновить на сайте
    v-spacer
  v-container
    v-dialog(v-model="edit_dialog" width="85vw")
      v-card(v-if="c_item")
        v-container.px-5(fluid)
          ArticleImages(:item="c_item")
          
          v-row(dense)
            v-col(cols=2)  
              v-text-field(v-model="c_item.article" label="Артикль" disabled autocomplete="off")
            v-col(cols=4)
              v-text-field(v-model="c_item.name" label="Название" autocomplete="off")
            v-col(v-for="f in fields" :cols="f.num ? 1 : 2" :key="f.id")
              v-text-field(v-if="f.num" v-model.number="c_item[f.id]" :label="f.label" type="number" autocomplete="off")
              v-select(v-else v-model="c_item[f.id]" :label="f.label" :items="f.items" clearable)
          
          v-row(justify="center" align="center")
            v-col(cols=6 v-for="(a, n) in [c_profile, c_skirting]" :key="n")
              v-card.grey.darken-3
                v-container
                  .headline.text-center.mb-2 {{ n == 0 ? 'Профиль' : 'Плинтус'}}
                  ArticleImages(:item="a")
                  v-row(v-if="a" dense justify="center")
                    v-col(cols=2)
                      v-text-field(v-model="a.article" label="Артикль" disabled autocomplete="off")
                    v-col(cols=8)
                      v-text-field(v-model="a.name" label="Название" autocomplete="off")
                    v-col(cols=2) 
                      v-text-field(v-model.number="a.price" label="Цена" type="number" autocomplete="off")
                  v-row(v-else dense justify="center")
                    v-btn(small) Добавить

          v-card-actions.justify-space-around
            v-btn(color="red" disabled)
              v-icon mdi-delete
              | Удалить
            v-btn(color="green" @click="save")
              v-icon mdi-content-save
              | Сохранить

    v-row(align="center" justify="center").pb-12
      v-col(cols=6 md=4 lg=3 xl=2 v-for="item in pageItems" :key="item.article")
        v-card(hover @click="c_item = JSON.parse(JSON.stringify(item)); edit_dialog = true")
          v-img.align-end(aspect-ratio=1 contain :src="`/images/${item.article}/preview.jpg`")
          v-card-title.subtitle-2.text-no-wrap {{ item.name }}
          v-card-subtitle Quick-Step {{ item.collection }}
          v-card-text
            span.caption.text--disabled {{ item.article }}
            span.float-right.subtitle-1.white--text(v-if="item.price") {{ item.price }} ₽
    v-footer(fixed)
      v-pagination(v-model="page" :length="pagesCount" color="primary")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { db } from '../db'
import axios from 'axios'
import ArticleImages from '@/components/ArticleImages.vue'

export default {
  name: 'Manage',
  data() {
    return {
      edit_dialog: false,
      c_item: null,
      updating: false,
    }
  },
  components: {
    ArticleImages,
  },
  watch: {},
  mounted() {
    this.setItems()
  },
  computed: {
    ...mapGetters([
      'pageItems',
      'pagesCount',
      'getAccById',
      'collections',
      'grooves_types',
      'colors',
      'gloss_levels',
      'water_reses',
    ]),
    page: {
      get() {
        return this.$store.state.page
      },
      set(value) {
        this.$store.commit('SET_PAGE', value)
      },
    },
    fields() {
      return [
        { id: 'collection', label: 'Коллекция', items: this.collections },
        { id: 'grooves_type', label: 'Тип фасок', items: this.grooves_types },
        { id: 'color', label: 'Цвет', items: this.colors },
        { id: 'gloss_level', label: 'Глянец', items: this.gloss_levels },
        { id: 'water_res', label: 'Водостойкость', items: this.water_reses },
        { id: 'length', label: 'Длина', num: true },
        { id: 'width', label: 'Ширина', num: true },
        { id: 'thickness', label: 'Толщина', num: true },
        { id: 'm2', label: 'м²/уп', num: true },
        { id: 'planks', label: 'Досок/уп', num: true },
        { id: 'warranty', label: 'Гарантия', num: true },
        { id: 'class', label: 'Класс', num: true },
        { id: 'price', label: 'Цена', num: true },
      ]
    },
    c_profile() {
      return this.c_item.profile
        ? JSON.parse(JSON.stringify(this.getAccById(this.c_item.profile)))
        : null
    },
    c_skirting() {
      return this.c_item.skirting
        ? JSON.parse(JSON.stringify(this.getAccById(this.c_item.skirting)))
        : null
    },
  },
  methods: {
    ...mapActions(['updateItem', 'updateAccessory', 'setItems']),
    save() {
      db.collection('items').doc(this.c_item.article).set(this.c_item)
      this.updateItem(this.c_item)

      for (const a of [this.c_profile, this.c_skirting]) {
        if (a !== null) {
          db.collection('accessories').doc(a.article).set(a)
          this.updateAccessory(a)
        }
      }
      this.edit_dialog = false
    },
    async updateJson() {
      this.updating = true
      await axios.post('/api/update_json')
      this.updating = false
    },
  },
}
</script>