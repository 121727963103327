<template lang="pug">
  v-row(align="center" justify="center" dense v-if="item")
    v-col(:cols="item.images > 3 ? 0 : 2")
      v-img.align-end(aspect-ratio=1 :src="`/images/${item.article}/preview.jpg`" max-height=500)
        span.pa-2.title preview
    v-col(:cols="item.images > 3 ? 0 : 2" v-for="(n, i) in item.images" :key="i")
      v-img.align-end(aspect-ratio=1 :src="`/images/${item.article}//${i}.jpg`" max-height=500)
        span.pa-2.title {{ i }}
</template>

<script>
export default {
  name: 'ArticleImages',
  props: {
    item: Object
  },
  data() {
    return {}
  }
}
</script>