import firebase from 'firebase/app'
import 'firebase/firestore'

// Get a Firestore instance
export const db = firebase
    .initializeApp({
        apiKey: "AIzaSyCZNZUfYEpaY0VPClVQZu51mjv51oTg5b8",
        authDomain: "laminat-shop.firebaseapp.com",
        databaseURL: "https://laminat-shop.firebaseio.com",
        projectId: "laminat-shop",
        storageBucket: "laminat-shop.appspot.com",
        messagingSenderId: "1069336514136",
        appId: "1:1069336514136:web:9d095e626f3ba71acb042d",
        measurementId: "G-2W0S1R9PTL"
    })
    .firestore()
